import { cva, type VariantProps } from "cva";

import { type SpriteName } from "#src/components/Sprite/index.ts";

export const commonButtonClassName = cva(
  "inline-flex items-center border transition-all focus:outline-none focus:ring-2 focus:ring-offset-1",
  {
    // compoundVariants describe relationships between variant styles.
    // Each outputs `className` when the other conditions match
    compoundVariants: [
      {
        className: "focus:ring-brand-500",
        // changes the focus ring if intent is in this list
        color: ["primary", "secondary"],
      },
      {
        className: [
          "border-brand-950 bg-brand-950 text-neutral-100 hover:bg-brand-700 active:bg-brand-950",
          "disabled:bg-brand-950 disabled:hover:bg-brand-950 disabled:active:bg-brand-950",
        ],
        color: "primary",
        treatment: "solid",
      },
      {
        className: [
          "text-brand-950 hover:text-brand-500 active:text-brand-950",
          "disabled:hover:text-brand-950 disabled:active:text-brand-950",
        ],
        color: "primary",
        treatment: "ghost",
      },
      {
        className: [
          "border-brand-950 bg-white text-brand-950 hover:bg-lime-50 active:bg-lime-100",
          "disabled:bg-white disabled:hover:bg-white disabled:active:bg-white",
        ],
        color: "secondary",
        treatment: "solid",
      },
      {
        className: "text-neutral-800 hover:text-stone-400 active:text-gray-900",
        color: "secondary",
        treatment: "ghost",
      },
      {
        className: [
          "border-red-800 bg-red-600 text-white hover:bg-red-400 active:bg-red-800",
          "disabled:hover:bg-red-600 disabled:active:bg-red-600",
        ],
        color: "danger",
        treatment: "solid",
      },
      {
        className: "text-red-600 hover:text-red-400 active:text-red-800",
        color: "danger",
        treatment: "ghost",
      },
      {
        className: "border-neutral-200 bg-stone-100 text-neutral-400",
        color: "disabled",
        treatment: "solid",
      },
      {
        className: "text-neutral-500",
        color: "disabled",
        treatment: "ghost",
      },
    ],
    defaultVariants: {
      color: "primary",
      treatment: "solid",
    },
    variants: {
      color: {
        danger: "focus:ring-red-600",
        disabled: "",
        primary: "",
        secondary: "",
      },
      treatment: {
        ghost: "border-transparent bg-transparent",
        solid: "",
      },
    },
  }
);

export type CommonStylingProps = VariantProps<typeof commonButtonClassName>;

export type CommonButtonProps = CommonStylingProps & {
  icon?: SpriteName;
  iconClassName?: string;
  loading?: boolean;
};
