import { useEffect, useState } from "react";

import { MessageType, sendMessage } from "#src/services/extension.client.ts";
import { safeJoinWithColon } from "#src/utils/safeJoin.ts";

/**
 * Returns the datetime that the tab was first loaded on.
 *
 * The tab load time will persist across page refreshes and url changes. If the
 * tab is closed and reopened, the state will be reset. These states are consistent
 * across both the traditional web view and iFrame view via PineCONE in Elation.
 */
export function useTabLoadTime() {
  const [loadTime, setLoadTime] = useState(new Date());

  useEffect(() => {
    const initialize = async () => {
      // If running within the extension iFrame, we need to get the
      // tab id since elation copies sessionStorage values across tabs
      const tabId = await getTabId();
      const key = safeJoinWithColon("tabLoadTime", tabId);

      const time = window.sessionStorage.getItem(key);
      if (time !== null) {
        setLoadTime(new Date(time));
        return;
      }

      const now = new Date();
      window.sessionStorage.setItem(key, now.toISOString());
      setLoadTime(now);
    };

    initialize();
  }, []);

  return loadTime;
}

async function getTabId() {
  try {
    const data = await sendMessage({ type: MessageType.GET_TAB_ID });
    return data?.tabId ?? null;
  } catch {
    return null;
  }
}
