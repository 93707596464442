const isStringOrNumber = (
  variableToCheck: unknown
): variableToCheck is string | number => {
  return (
    (typeof variableToCheck === "string" && variableToCheck.length > 0) ||
    typeof variableToCheck === "number" ||
    typeof variableToCheck === "bigint"
  );
};

// safeJoin is a HOF to build fns to nicely join strings.
// the returned fn takes in variadic arguments, and returns all truthy components with the delimiter.
//
// safeJoin :: String -> [a] -> String
export const safeJoin =
  (delimiter: string) =>
  (...xs: unknown[]) =>
    xs
      .flat()
      .filter(isStringOrNumber)
      .map(x => {
        if (typeof x !== "string") {
          return x.toString();
        }

        return x.trim();
      })
      .join(delimiter);

// fn to join all arguments with an empty string
export const safeJoinTogether = safeJoin("");

// fn to join all arguments with a single space
export const safeJoinWithSpace = safeJoin(" ");

// fn to join all arguments with a comma
export const safeJoinWithComma = safeJoin(", ");

// fn to join all arguments with En dash
export const safeJoinWithEnDash = safeJoin("–");

export const safeJoinWithSpacedEnDash = safeJoin(" – ");

// fn to join all arguments with Em dash
export const safeJoinWithEmDash = safeJoin(" — ");

// fn to join all arguments with a new line
export const safeJoinWithNewLine = safeJoin("\n");

// fn to join all arguments with a colon
export const safeJoinWithColon = safeJoin(":");

export const safeJoinWithSpacedDot = safeJoin(" • ");

export const safeJoinWithColonSpace = safeJoin(": ");

/** joins with `?`, useful for adding searchParams to url/pathname */
export const safeJoinWithQuestionMark = safeJoin("?");

export const safeJoinPageTitle = (...xs: unknown[]) =>
  safeJoin(" | ")(...xs, "Pine Park Health");
