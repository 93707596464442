import { useCallback, useEffect, useState } from "react";

import { useSafeTimeout } from "#src/utils/useSafeTimeout.ts";

export type UseHoverWithGracePeriodOptions = {
  closeDelayMs?: number;
};

export function useHoverWithGracePeriod({
  closeDelayMs = 250,
}: UseHoverWithGracePeriodOptions = {}) {
  const safeTimeout = useSafeTimeout();
  const [isHovering, setIsHovering] = useState(false);

  const onPointerOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const onPointerOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const [isActive, setIsActive] = useState(isHovering);

  useEffect(() => {
    if (isHovering === false) {
      const clearTimeout = safeTimeout(() => setIsActive(false), closeDelayMs);

      return () => {
        clearTimeout();
      };
    }

    setIsActive(true);
  }, [closeDelayMs, isHovering, safeTimeout]);

  return [isActive, { onPointerOut, onPointerOver }] as const;
}
