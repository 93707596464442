import { type RemixLinkProps } from "@remix-run/react/dist/components.js";
import { cva, cx, type VariantProps } from "cva";
import { forwardRef } from "react";

import { ButtonContent } from "#src/components/Buttons/ButtonContent.tsx";
import {
  type CommonButtonProps,
  commonButtonClassName,
} from "#src/components/Buttons/common.ts";
import { Link } from "#src/components/Link.tsx";

const iconButtonClassName = cva("relative rounded-sm", {
  defaultVariants: { shape: "square", size: "md" },
  variants: {
    shape: {
      round: "!rounded-full",
      square: "",
    },
    size: {
      lg: "h-12 w-12",
      md: "h-10 w-10",
      sm: "h-8 w-8",
    },
  },
});

const iconButtonIconClassName = cva("absolute", {
  defaultVariants: { size: "md" },
  variants: {
    size: {
      lg: "h-6 w-6",
      md: "h-5 w-5",
      sm: "h-4 w-4",
    },
  },
});

export type IconButtonProps = JSX.IntrinsicElements["button"] &
  CommonButtonProps &
  Required<Pick<CommonButtonProps, "icon">> & {
    iconSizeOverride?: VariantProps<typeof iconButtonIconClassName>["size"];
    srText: string;
  } & VariantProps<typeof iconButtonClassName>;

/**
 * IconButton
 *
 * Renders an icon-only button and its Screen Reading affordances
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    {
      className,
      color,
      disabled,
      icon,
      iconClassName,
      iconSizeOverride,
      loading = false,
      treatment,
      srText,
      size,
      shape,
      title,
      type = "button",
      ...props
    },
    ref
  ) {
    return (
      <button
        className={cx(
          commonButtonClassName({
            treatment,
            color: disabled === true ? "disabled" : color,
          }),
          iconButtonClassName({ shape, size }),
          className
        )}
        disabled={loading || disabled}
        ref={ref}
        title={title || srText}
        type={type}
        {...props}
      >
        <ButtonContent
          className="w-full justify-center"
          aria-hidden={true}
          icon={icon}
          iconClassName={iconButtonIconClassName({
            className: iconClassName,
            size: iconSizeOverride ?? size,
          })}
          loading={loading}
          title={srText}
        >
          <span className="sr-only">{srText}</span>
        </ButtonContent>
      </button>
    );
  }
);

export type LinkIconButtonProps = IconButtonProps & RemixLinkProps;

/**
 * IconButton
 *
 * Renders an icon-only link as a button with the same Screen Reading, icon,
 * and loading indicator support as IconButton
 *
 */
export const LinkIconButton = forwardRef<HTMLAnchorElement, LinkIconButtonProps>(
  function LinkIconButton(
    {
      children,
      className,
      icon,
      iconClassName,
      color,
      loading = false,
      treatment,
      srText,
      size,
      ...props
    },
    ref
  ) {
    return (
      <Link
        ref={ref}
        className={cx(
          commonButtonClassName({
            color,
            treatment,
          }),
          iconButtonClassName({
            size,
          }),
          className
        )}
        {...props}
      >
        <ButtonContent
          aria-hidden={true}
          className="w-full justify-center"
          iconClassName={iconButtonIconClassName({
            size,
            className: iconClassName,
          })}
          icon={icon}
          loading={loading}
          title={srText}
        >
          <span className="sr-only">{srText}</span>
        </ButtonContent>
      </Link>
    );
  }
);
