import { Link } from "@remix-run/react";
import { type RemixLinkProps } from "@remix-run/react/dist/components.js";
import { cva, cx, type VariantProps } from "cva";

import {
  ButtonContent,
  type ButtonContentProps,
} from "#src/components/Buttons/ButtonContent.tsx";
import {
  commonButtonClassName,
  type CommonStylingProps,
} from "#src/components/Buttons/common.ts";
import { useHoverWithGracePeriod } from "#src/utils/useHoverWithGracePeriod.tsx";

const expandingButtonClassName = cva(
  "group/expanding-button flex items-center whitespace-nowrap rounded-sm transition-all",
  {
    compoundVariants: [
      {
        className: "pr-0",
        size: "sm",
        state: "base",
      },
      {
        className: "pr-2",
        size: "sm",
        state: "expanded",
      },
      {
        className: "pr-0",
        size: "md",
        state: "base",
      },
      {
        className: "pr-2",
        size: "md",
        state: "expanded",
      },
      {
        className: "pr-0",
        size: "lg",
        state: "base",
      },
      {
        className: "pr-4",
        size: "lg",
        state: "expanded",
      },
    ],
    defaultVariants: {
      anchor: "start",
      size: "md",
      state: "base",
    },
    variants: {
      anchor: {
        start: "",
        end: "",
      },
      size: {
        lg: "h-12 min-w-6 pl-12 text-lg font-medium",
        md: "h-10 min-w-5 pl-8 font-medium",
        sm: "h-8 min-w-4 pl-8 text-sm font-medium",
      },
      state: {
        base: "",
        expanded: "is-expanded max-w-full",
      },
    },
  }
);

export const expandingButtonIconClassName = cva("absolute", {
  compoundVariants: [
    {
      anchor: "start",
      className: "-left-6",
      size: "sm",
    },
    {
      anchor: "start",
      className: "-left-6",
      size: "md",
    },
    {
      anchor: "start",
      className: "-left-6",
      size: "lg",
    },
  ],
  defaultVariants: { anchor: "start", size: "md" },
  variants: {
    anchor: {
      start: "",
      end: "",
    },
    size: {
      lg: "h-6 w-6",
      md: "h-5 w-5",
      sm: "h-4 w-4",
    },
  },
});

type ExpandingButtonStyleProps = CommonStylingProps &
  VariantProps<typeof expandingButtonClassName>;

type CommonExpandingButtonProps = Pick<
  JSX.IntrinsicElements["button"],
  "disabled"
> & {
  children: string;
  /* How long should we delay closing of expanded state */
  closeDelayMs?: number;
} & ButtonContentProps;

export type ExpandingButtonProps = CommonExpandingButtonProps &
  ExpandingButtonStyleProps &
  JSX.IntrinsicElements["button"];

/**
 * ExpandingButton
 *
 * Renders an IconButton style button that expands on hover to include a label
 */
export function ExpandingButton({
  anchor,
  className,
  closeDelayMs,
  color,
  children,
  disabled,
  icon,
  iconClassName,
  loading,
  size,
  type = "button",
  ...props
}: ExpandingButtonProps) {
  const [isExpanded, handlers] = useHoverWithGracePeriod({ closeDelayMs });

  return (
    <button
      className={cx(
        commonButtonClassName({
          color: disabled === true ? "disabled" : color,
        }),
        expandingButtonClassName({
          anchor,
          size,
          state: isExpanded ? "expanded" : "base",
        }),
        className
      )}
      disabled={disabled}
      type={type}
      {...handlers}
      {...props}
    >
      <ButtonContent
        aria-hidden={true}
        icon={icon}
        iconClassName={expandingButtonIconClassName({
          size,
          className: iconClassName,
        })}
        loading={loading}
        title={children}
      >
        <span className="max-w-0 opacity-0 group-[.is-expanded]/expanding-button:max-w-full group-[.is-expanded]/expanding-button:opacity-100">
          {children}
        </span>
      </ButtonContent>
    </button>
  );
}

type WithPrefix<P extends string, T> = T extends `${P}${string}` ? T : never;

type RemixLinkPropsWithoutEventHandlers = Omit<
  RemixLinkProps,
  WithPrefix<"on", keyof JSX.IntrinsicElements["div"]>
>;

export type ExpandingLinkButtonProps = CommonExpandingButtonProps &
  ExpandingButtonStyleProps &
  RemixLinkPropsWithoutEventHandlers;

/**
 * ExpandingLinkButton
 *
 * Renders an LinkIconButton style link that expands on hover to include a label
 */
export function ExpandingLinkButton({
  anchor,
  className,
  closeDelayMs,
  color,
  children,
  disabled,
  icon,
  iconClassName,
  loading,
  size,
  prefetch,
  ...props
}: ExpandingLinkButtonProps) {
  const [isExpanded, handlers] = useHoverWithGracePeriod({ closeDelayMs });

  const linkClassName = cx(
    commonButtonClassName({
      color: disabled === true ? "disabled" : color,
    }),
    expandingButtonClassName({
      anchor,
      size,
      state: isExpanded ? "expanded" : "base",
    }),
    className
  );

  const content = (
    <ButtonContent
      aria-hidden={true}
      icon={icon}
      iconClassName={expandingButtonIconClassName({
        size,
        className: iconClassName,
      })}
      loading={loading}
      title={children}
    >
      <span className="max-w-0 opacity-0 group-[.is-expanded]/expanding-button:max-w-full group-[.is-expanded]/expanding-button:opacity-100">
        {children}
      </span>
    </ButtonContent>
  );

  if (disabled) {
    return (
      <div className={linkClassName} {...handlers} {...props}>
        {content}
      </div>
    );
  }

  return (
    <Link className={linkClassName} prefetch={prefetch} {...handlers} {...props}>
      {content}
    </Link>
  );
}
