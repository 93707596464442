import {
  useCallback,
  useRef,
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useContext,
} from "react";
import { createPortal as createReactPortal } from "react-dom";

type DragPortalContext = {
  createPortal: (contents: ReactNode) => React.ReactPortal | undefined;
};

const dragPortalContext = createContext<DragPortalContext | null>(null);

export function DragPortal({ children }: PropsWithChildren) {
  const ref = useRef<HTMLDivElement>(null);
  const createPortal = useCallback((contents: ReactNode) => {
    if (!ref.current) return;
    return createReactPortal(contents, ref.current);
  }, []);

  return (
    <>
      <dragPortalContext.Provider value={{ createPortal }}>
        {children}
      </dragPortalContext.Provider>
      <div ref={ref} />
    </>
  );
}

export function useCreateDragPortal() {
  const context = useContext(dragPortalContext);
  if (!context)
    throw new Error("useCreateDragPortal must be used within a DragPortal");
  return context.createPortal;
}
