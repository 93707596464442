/**
 * This file contains utilities for using client hints for user preference which
 * are needed by the server, but are only known by the browser.
 *
 * Original code from: https://github.com/epicweb-dev/epic-stack/blob/main/app/utils/client-hints.tsx
 */
import { type SerializeFrom } from "@remix-run/node";
import { useRouteLoaderData } from "@remix-run/react";
import { type HTMLAttributes } from "react";

import { type loader } from "#src/root.tsx";
import { Timezone } from "#src/utils/timezones.ts";

const clientHints = {
  isIframe: {
    cookieName: "CH-iframe",
    fallback: "true",
    getValueCode: `(function inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    })()
    `,
  },
  timezone: {
    cookieName: "CH-time-zone",
    fallback: Timezone.UTC,
    getValueCode: "Intl.DateTimeFormat().resolvedOptions().timeZone",
  },
};

type ClientHintNames = keyof typeof clientHints;

/**
 *
 * @param request {Request} - optional request object (only used on server)
 * @returns an object with the client hints and their values
 */
export function getHints(request?: Request) {
  const cookieString =
    typeof document !== "undefined"
      ? document.cookie
      : typeof request !== "undefined"
        ? (request.headers.get("Cookie") ?? "")
        : "";

  const hints = Object.fromEntries(
    Object.entries(clientHints).map(([name, hint]) => {
      const hintName = name as ClientHintNames;
      return [name, getCookieValue(cookieString, hintName) ?? hint.fallback];
    })
  );

  return hints as { [K in ClientHintNames]: string };
}

/**
 * @returns an object with the client hints and their values
 */
export function useHints() {
  const data = useRouteLoaderData("root") as SerializeFrom<typeof loader>;
  return { ...data.hints, isIframe: JSON.parse(data.hints.isIframe) as boolean };
}

/**
 * @returns inline script element that checks for client hints and sets cookies
 * if they are not set then reloads the page if any cookie was set to an
 * inaccurate value.
 */
export function ClientHintCheck(
  props: Omit<HTMLAttributes<HTMLScriptElement>, "dangerouslySetInnerHTML">
) {
  return (
    <script
      {...props}
      dangerouslySetInnerHTML={{
        __html: `
const cookies = document.cookie
  .split(";")
  .map((c) => c.trim())
  .reduce((acc, cur) => {
    const [key, value] = cur.split("=");
    acc[key] = value;
    return acc;
  }, {});

let cookieChanged = false;
const hints = [
  ${Object.values(clientHints)
    .map(hint => {
      const cookieName = JSON.stringify(hint.cookieName);
      return `{ name: ${cookieName}, actual: String(${hint.getValueCode}), cookie: cookies[${cookieName}] }`;
    })
    .join(",\n")}
];

for (const hint of hints) {
  if (decodeURIComponent(hint.cookie) !== hint.actual) {
    cookieChanged = true;
    document.cookie =
      encodeURIComponent(hint.name) +
      "=" +
      encodeURIComponent(hint.actual) +
      ";path=/;Secure;Partitioned;SameSite=None;";
  }
}

// if the cookie changed, reload the page, unless the browser doesn't support
// cookies (in which case we would enter an infinite loop of reloads)
if (cookieChanged && navigator.cookieEnabled) {
  window.location.reload();
}`,
      }}
    />
  );
}

function getCookieValue(cookieString: string, name: ClientHintNames) {
  const hint = clientHints[name];
  if (!hint) {
    throw new Error(`Unknown client hint: ${name}`);
  }
  const value = cookieString
    .split(";")
    .map(c => c.trim())
    .find(c => c.startsWith(hint.cookieName + "="))
    ?.split("=")[1];

  return value ? decodeURIComponent(value) : null;
}
