import { Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { Fragment, type PropsWithChildren } from "react";

import { Sprite, type SpriteName } from "#src/components/Sprite/index.ts";
import {
  type ToastErrorMessage,
  type ToastMessage,
  type ToastSuccessMessage,
} from "#src/utils/flash.server.ts";

type ToastTemplateProps<M extends ToastMessage> = {
  message: M;
  visible: boolean;
};
type ToastTemplateMap = {
  [key in ToastMessage["template"]]: React.FC<
    ToastTemplateProps<Extract<ToastMessage, { template: key }>>
  >;
};

const flashToastTemplateMap: ToastTemplateMap = {
  error: ErrorToast,
  success: SuccessToast,
};

export function FlashToast({
  visible,
  message,
}: ToastTemplateProps<ToastMessage>) {
  // NOTE: Using "as" cast to widen the type of the component to prevent type errors.
  const Component = flashToastTemplateMap[message.template] as React.FC<
    ToastTemplateProps<ToastMessage>
  >;
  return <Component visible={visible} message={message} />;
}

function SuccessToast({
  visible,
  message,
}: {
  message: ToastSuccessMessage;
  visible: boolean;
}) {
  return (
    <UpdateToast
      visible={visible}
      icon="checkmarkCircle"
      iconClassName="bg-green-500 text-green-50"
    >
      <p className="text-base font-medium text-gray-900">{message.message}</p>
    </UpdateToast>
  );
}

function ErrorToast({
  visible,
  message,
}: {
  message: ToastErrorMessage;
  visible: boolean;
}) {
  return (
    <UpdateToast
      visible={visible}
      icon="exclamationCircle"
      iconClassName="bg-red-500 text-red-50"
    >
      <p className="text-base font-medium text-gray-900">{message.message}</p>
    </UpdateToast>
  );
}

type UpdateToastProps = PropsWithChildren<{
  icon: SpriteName;
  iconClassName?: string;
  visible: boolean;
}>;
function UpdateToast({
  visible,
  children,
  icon,
  iconClassName,
}: UpdateToastProps) {
  return (
    <Transition
      show={visible}
      appear={true}
      as={Fragment}
      enter="transition duration-300"
      enterFrom="translate-y-full opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition duration-100"
      leaveFrom="translate-y-0 opacity-100"
      leaveTo="translate-y-full opacity-0"
    >
      <div className="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="w-0 flex-1 p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <Sprite
                className={clsx("h-8 w-8 rounded-full", iconClassName)}
                name={icon}
              />
            </div>
            <div className="ml-3 flex-1 self-center">{children}</div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
