import { clsx } from "clsx";

import { Sprite, type SpriteProps } from "#src/components/Sprite/index.ts";

type SpinnerProps = {
  showSpinner: boolean;
} & Omit<SpriteProps, "name">;

export function Spinner({ showSpinner, className, ...props }: SpinnerProps) {
  return (
    <Sprite
      name="spinner"
      className={clsx(
        "pointer-events-none animate-spin transition-opacity",
        showSpinner ? "opacity-100" : "opacity-0",
        className
      )}
      {...props}
    />
  );
}
