import spriteSrc from "#src/assets/sprites.svg";
import { type SpriteName } from "#src/components/Sprite/config.ts";

export type SpriteProps = Pick<
  JSX.IntrinsicElements["svg"],
  "className" | "preserveAspectRatio"
> & {
  minX?: number;
  minY?: number;
  name: SpriteName;

  /** Due to the way `use` works, we need to set a viewBox on the wrapping svg
   * element. HeroIcons, Feather icons both use 24px scale and I think it will
   * work for the other react-icons libraries as well, but if you need to tweak
   * this on an icon, here you go.
   */
  scaleFactor?: number;

  title?: string;
};

export function Sprite({
  name,
  minX = 0,
  minY = 0,
  preserveAspectRatio = "xMidYMid slice",
  scaleFactor = 24,
  title,
  ...svgProps
}: SpriteProps) {
  return (
    <svg
      aria-hidden={true}
      preserveAspectRatio={preserveAspectRatio}
      viewBox={`${minX} ${minY} ${scaleFactor} ${scaleFactor}`}
      {...svgProps}
    >
      <title>{title ?? name}</title>
      <use href={`${spriteSrc}#${name}`} />
    </svg>
  );
}
