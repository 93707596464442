/* eslint-disable @typescript-eslint/no-redeclare */

export const Timezone = {
  AMERICA_CHICAGO: "America/Chicago",
  AMERICA_LOS_ANGELES: "America/Los_Angeles",
  AMERICA_NEW_YORK: "America/New_York",
  AMERICA_PHOENIX: "America/Phoenix",
  UTC: "Etc/UTC",
};
export type Timezone = (typeof Timezone)[keyof typeof Timezone];
