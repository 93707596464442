import { Form } from "@remix-run/react";

import logoSrc from "#src/assets/logo.png";
import { Button } from "#src/components/Buttons/index.ts";

export default function Component() {
  return (
    <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={logoSrc}
            alt="Pine Park Health"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <Form
          className="flex items-center justify-center"
          action="/auth/google"
          method="POST"
        >
          <Button color="secondary" size="lg" type="submit" icon="googleLogo">
            Sign in with Google
          </Button>
        </Form>
      </div>
    </div>
  );
}
