import { useEffect, useRef, useCallback } from "react";

type TimeoutID = ReturnType<typeof setTimeout>;

export function useSafeTimeout() {
  const timeoutIds = useRef<TimeoutID[]>([]);

  useEffect(() => {
    return () => {
      timeoutIds.current.forEach(timeoutId => {
        clearTimeout(timeoutId);
      });
      timeoutIds.current = [];
    };
  }, []);

  const startTimeout = useCallback((callback: () => void, ms = 0) => {
    const timeoutId = setTimeout(callback, ms);

    timeoutIds.current = [...timeoutIds.current, timeoutId];

    const cancelTimeout = () => {
      if (timeoutIds.current.includes(timeoutId)) {
        clearTimeout(timeoutId);
        timeoutIds.current = timeoutIds.current.filter(id => id !== timeoutId);
      }
    };

    return cancelTimeout;
  }, []);

  return startTimeout;
}
