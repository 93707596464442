import { ZodError } from "zod";
import { fromZodError } from "zod-validation-error";

/** An error that is expected to occur in the regular course of the application.
 *
 * These errors are not caused by bugs in the application, but rather by user input or
 * other external factors. These errors are not considered fatal, and should be handled
 * gracefully. They may be logged, but should not reported to Error Monitoring (i.e. Sentry).
 */
export class ExpectedError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    this.name = this.constructor.name;
  }
}

export class AuthError extends ExpectedError {}

/** For BullMQ jobs, an error that is expected to occur and is considered unrecoverable.
 *
 * These errors will not retry jobs, nor be reported to Error Monitoring (i.e. Sentry). Useful
 * for marking a job as failed without triggering an alert.
 *
 * NOTE: This error must remain named "UnrecoverableError" for BullMQ to recognize it:
 * https://github.com/taskforcesh/bullmq/blob/67e5df913f65ed94dc3500480bee785a7d01f57b/src/classes/job.ts#L591
 */
export class UnrecoverableError extends ExpectedError {}

export class ValidationError extends ExpectedError {}

/** An error that could be encountered in normal operations but should be retried without reporting to Sentry
 *
 * This should be subclassed and used with the `withRetriableErrors` HOF in async queue handlers
 */
export class RetriableError extends ExpectedError {}

/** An error expected to occur from time to time. This operation should be marked
 * for retry without reporting to Sentry.
 *
 * Used with the `withRetriableErrors` HOF in async queue handlers.
 */
export class IntermittentError extends RetriableError {}

export class UnreachableCaseError extends Error {
  constructor(value: never) {
    super(`Unreachable case: ${value}`);
  }
}

export function getFieldErrorsFromError(err: unknown) {
  if (err instanceof ZodError) {
    return Object.entries(err.formErrors.fieldErrors).flatMap(
      ([fieldName, errors]) => {
        if (!errors) return [];

        return [
          {
            errors,
            fieldName,
          },
        ];
      }
    );
  }

  return [];
}

/**
 * @deprecated Use `translateZodErrorToDictWithSchemaPaths` instead.
 */
export function getFieldErrorsDictFromError(err: unknown) {
  if (err instanceof ZodError) {
    return Object.fromEntries(
      Object.entries(err.formErrors.fieldErrors).flatMap(
        ([fieldName, errors]) => {
          if (!errors) return [];

          return [[fieldName, errors]];
        }
      )
    );
  }

  return {};
}

/**
 * Translates a ZodError into a dictionary with schema paths as keys and error messages as values.
 */
export function translateZodErrorToDictWithSchemaPaths(err: unknown) {
  if (!(err instanceof ZodError)) {
    return {};
  }

  const validationError = fromZodError(err, { includePath: true });
  return Object.fromEntries(
    validationError.details.map(issue => {
      return [issue.path.join("."), issue.message];
    })
  );
}

export function getErrorMessage(
  err: unknown,
  fallbackMessage = "Encountered an error"
) {
  if (err instanceof ZodError) {
    return fromZodError(err, { includePath: false }).message || fallbackMessage;
  }

  return ensureError(err, fallbackMessage).message;
}

type StringableType = { toString: () => string };

const isStringable = (
  variableToCheck: unknown
): variableToCheck is StringableType => {
  return Boolean(
    variableToCheck &&
      typeof variableToCheck === "object" &&
      typeof variableToCheck.toString === "function"
  );
};

export function ensureError<T>(
  err: T,
  fallbackErrorMsg = "Encountered an unknown error."
) {
  if (err) {
    if (err instanceof Error) return err;
    if (typeof err === "string") return new Error(err);
    if (isStringable(err)) return new Error(err.toString());
  }

  return new Error(fallbackErrorMsg);
}
