import { Link as RemixLink } from "@remix-run/react";
import { type RemixLinkProps } from "@remix-run/react/dist/components.js";
import { cva, type VariantProps } from "cva";
import { useCallback, type PropsWithChildren } from "react";

import { MessageType, sendMessage } from "#src/services/extension.client.ts";
import { useHints } from "#src/utils/client-hints.tsx";

const linkClassName = cva("text-brand-900 transition-all hover:text-brand-700", {
  defaultVariants: {
    intent: "base",
  },
  variants: {
    intent: {
      base: "underline",
    },
  },
});

type PPHLinkProps = VariantProps<typeof linkClassName>;
export type LinkProps = RemixLinkProps & PPHLinkProps;

export function Link({ className, intent, ...props }: LinkProps) {
  return (
    <RemixLink
      className={linkClassName({
        className,
        intent,
      })}
      {...props}
    />
  );
}

type ExternalLinkProps = JSX.IntrinsicElements["a"] & PPHLinkProps;
function ExternalLink({ className, intent, ...props }: ExternalLinkProps) {
  return (
    <a
      className={linkClassName({
        className,
        intent,
      })}
      {...props}
      rel="noopener noreferrer"
    />
  );
}

type HTMLButtonProps = JSX.IntrinsicElements["button"];
type ButtonLinkProps = PPHLinkProps &
  Omit<HTMLButtonProps, "onClick" | "type"> &
  Required<Pick<HTMLButtonProps, "onClick">>;

export function ButtonLink({
  className,
  intent,
  ...buttonProps
}: ButtonLinkProps) {
  return (
    <button
      type="button"
      className={linkClassName({
        className,
        intent,
      })}
      {...buttonProps}
    />
  );
}

type IsomorphicLinkProps = PropsWithChildren<
  {
    className?: string;
    newTab?: boolean;
    uri: string;
  } & PPHLinkProps
>;

export function IsomorphicLink({ newTab, uri, ...props }: IsomorphicLinkProps) {
  // TODO: Turn into NavLink so we can highlight the active link
  const { isIframe } = useHints();
  const { type, href } = parseUri(uri);
  const extensionPath = type === "pinecone" ? href : `/iframe${href}`;
  const target = newTab ? "_blank" : "";
  const navigateExtension = useCallback(() => {
    sendMessage({ to: extensionPath, type: MessageType.NAVIGATE });
  }, [extensionPath]);

  if (type === "external") {
    return <ExternalLink {...props} href={href} target={target} />;
  }

  if (type === "relative" && !isIframe) {
    return <Link {...props} target={target} to={href} />;
  }

  return (
    <ButtonLink
      {...props}
      data-extension-path={extensionPath}
      onClick={navigateExtension}
    />
  );
}

const relativeOrigin = "https://relative.test";
const pineconeOrigin = "https://pinecone.test";
function parseUri(uri: string) {
  const replaced = uri.replace("pinecone://", pineconeOrigin);
  const url = new URL(replaced, relativeOrigin);
  const type = uriTypeFromOrigin(url.origin);

  return {
    type,
    href: type === "external" ? uri : url.pathname + url.search,
  };
}

function uriTypeFromOrigin(origin: string) {
  if (origin === relativeOrigin) {
    return "relative";
  }

  if (origin === pineconeOrigin) {
    return "pinecone";
  }

  return "external";
}
