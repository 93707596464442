import { clsx } from "clsx";

import { type CommonButtonProps } from "#src/components/Buttons/common.ts";
import { Spinner } from "#src/components/Spinner.tsx";
import { Sprite, type SpriteProps } from "#src/components/Sprite/index.ts";

export type ButtonContentProps = Pick<
  JSX.IntrinsicElements["svg"],
  "children" | "aria-hidden"
> &
  Omit<SpriteProps, "name"> & {
    className?: string;
    icon?: CommonButtonProps["icon"];
    iconClassName?: CommonButtonProps["iconClassName"];
    loading?: CommonButtonProps["loading"];
  };

/**
 * ButtonContent
 *
 * Shared layout for Icon/Loading/Content used by Button components
 */
export function ButtonContent({
  className,
  icon,
  iconClassName,
  loading = false,
  children,
  ...props
}: ButtonContentProps) {
  return (
    <div className={clsx("relative inline-flex items-center", className)}>
      <Spinner className={iconClassName} showSpinner={loading} />
      {icon ? (
        <Sprite
          className={clsx(iconClassName, loading ? "opacity-0" : "opacity-100")}
          name={icon}
          {...props}
        />
      ) : null}
      {children}
    </div>
  );
}
