type PickReturnType<T, K extends keyof NonNullable<T>> = T extends null
  ? null
  : T extends undefined
    ? undefined
    : Pick<NonNullable<T>, K>;

export function pick<
  T extends Record<string | number | symbol, unknown> | null | undefined,
  K extends keyof NonNullable<T>,
>(obj: T, keys: readonly K[]): PickReturnType<T, K> {
  if (obj == null) {
    return obj as unknown as PickReturnType<T, K>;
  }

  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => keys.includes(key as K))
  ) as PickReturnType<T, K>;
}
